export default {
    Industries: [
        "IT",
        "Accounting",
        "Advertising/Public Relations",
        "Aerospace, Defense Contractors",
        "Agribusiness",
        "Agricultural Services & Products",
        "Agriculture",
        "Agriculture",
        "Air Transport",
        "Airlines",
        "Alcoholic Beverages",
        "Alternative Energy Production & Services",
        "Architectural Services",
        "Attorneys/Law Firms",
        "Auto Dealers",
        "Auto Dealers, Japanese",
        "Auto Manufacturers",
        "Automotive",
        "Banking, Mortgage",
        "Banks, Commercial",
        "Banks, Savings & Loans",
        "Beer, Wine & Liquor",
        "Books, Magazines & Newspapers",
        "Radio/TV",
        "Builders/General Contractors",
        "Builders/Residential",
        "Building Materials & Equipment",
        "Building Trade Unions",
        "Business Associations",
        "Business Services",
        "Cable & Satellite TV Production & Distribution",
        "Candidate Committees",
        "Car Dealers",
        "Car Dealers, Imports",
        "Car Manufacturers",
        "Chemical & Related Manufacturing",
        "Chiropractors",
        "Civil Servants/Public Officials",
        "Clergy & Religious Organizations",
        "Clothing Manufacturing",
        "Coal Mining",
        "Colleges, Universities & Schools",
        "Commercial Banks",
        "Commercial TV & Radio Stations",
        "Communications/Electronics",
        "Computer Software",
        "Conservative/Republican",
        "Construction",
        "Construction Services",
        "Construction Unions",
        "Credit Unions",
        "Crop Production & Basic Processing",
       "Cruise Lines",
        "Cruise Ships & Lines",
        "Dairy",
        "Defense",
        "Defense Aerospace",
        "Defense Electronics",
        "Defense/Foreign Policy Advocates",
        "Democratic Candidate Committees",
        "Dentists",
        "Doctors & Other Health Professionals",
        "Drug Manufacturers",
        "Education",
        "Electric Utilities",
        "Electronics Manufacturing & Equipment",
        "Electronics, Defense Contractors",
        "Energy & Natural Resources",
        "Entertainment Industry",
        "Environment",
        "Farming",
        "Finance / Credit Companies",
        "Finance, Insurance & Real Estate",
        "Food & Beverage",
        "Food Processing & Sales",
        "Food Products Manufacturing",
        "Food Stores",
        "Foreign & Defense Policy",
        "Forestry & Forest Products",
        "Foundations, Philanthropists & Non-Profits",
        "Funeral Services",
        "Garbage Collection/Waste Management",
        "Gas & Oil",
        "General Contractors",
        "Government Employee Unions",
        "Health",
        "Health Professionals",
        "Health Services/HMOs",
        "Hedge Funds",
        "HMOs & Health Care Services",
        "Home Builders",
        "Hospitals & Nursing Homes",
        "Hotels, Motels & Tourism",
        "Human Rights",
        "Industrial Unions",
        "Insurance",
        "Internet",
        "Labor",
        "Lawyers & Lobbyists",
        "Lawyers / Law Firms",
        "Liquor, Wine & Beer",
        "Livestock",
        "Lobbyists",
        "Lodging / Tourism",
        "Logging, Timber & Paper Mills",
        "Manufacturing",
        "Marine Transport",
        "Meat processing & products",
        "Medical Supplies",
        "Mining",
        "Mortgage Bankers & Brokers",
        "Motion Picture Production & Distribution",
        "Music Production",
        "Natural Gas Pipelines",
        "Newspaper, Magazine & Book Publishing",
        "Non-profits, Foundations & Philanthropists",
        "Nurses",
        "Nursing Homes/Hospitals",
        "Nutritional & Dietary Supplements",
        "Oil & Gas",
        "Other",
        "Payday Lenders",
        "Pharmaceutical Manufacturing",
        "Pharmaceuticals / Health Products",
        "Phone Companies",
        "Physicians & Other Health Professionals",
        "Postal Unions",
        "Poultry & Eggs",
        "Power Utilities",
        "Politics",
        "Printing & Publishing",
        "Private Equity & Investment Firms",
        "Professional Sports, Sports Arenas & Related Equipment & Services",
        "Progressive/Democratic",
        "Public Employees",
        "Public Sector Unions",
        "Publishing & Printing",
        "Radio/TV Stations",
        "Railroads",
        "Real Estate",
        "Record Companies/Singers",
        "Recorded Music & Music Production",
        "Recreation / Live Entertainment",
        "Religious Organizations/Clergy",
        "Republican/Conservative",
        "Residential Construction",
        "Restaurants & Drinking Establishments",
        "Retail Sales",
        "Retired",
        "Savings & Loans",
        "Schools/Education",
        "Sea Transport",
        "Securities & Investment",
        "Special Trade Contractors",
        "Sports, Professional",
        "Steel Production",
        "Stock Brokers/Investment Industry",
        "Student Loan Companies",
        "Sugar Cane & Sugar Beets",
        "Teachers Unions",
        "Teachers/Education",
        "Telecom Services & Equipment",
        "Telephone Utilities",
        "Textiles",
        "Timber, Logging & Paper Mills",
        "Tobacco",
        "Transportation",
        "Transportation Unions",
        "Trash Collection/Waste Management",
        "Trucking",
        "TV / Movies / Music",
        "TV Production",
        "Unions",
        "Universities, Colleges & Schools",
        "Vegetables & Fruits",
        "Venture Capital",
        "Waste Management",
        "Wine, Beer & Liquor"

    ]
}