<template>
  <layout>
    <template v-slot:header>
      <Header>
        <template #right-header-component>
          <div v-show="!first_login " >
            <BaseButton @click="goToAllOrganizationsPage" outlined button-text="Cancel"/>
          </div>

        </template>
      </Header>
    </template>
    <template v-slot:main-content>
      <div class="tw-h-full mb-5 main__content_wrap">
        <div v-show="$route.params.step === '1'&& !first_login " class="tw-m-auto pt-5 lg:tw-w-10/12">
          <button @click="goToAllOrganizationsPage" class="tw-flex">
            <ChevronLeftIcon/>
            <p class="tw-font-bold tw-text-base">Back</p>
          </button>

        </div>
        <div v-show="$route.params.step !== '1'" class="tw-m-auto pt-5 lg:tw-w-10/12">
          <button @click="goBack()" class="tw-flex">
            <ChevronLeftIcon/>
            <p class="tw-font-bold tw-text-base">Back</p>
          </button>
        </div>

        <div class="applyFlexCenter company__profile-content">
          <div>
            <v-card flat class="tw-w-80 tw-mt-10 tw-px-5 md:tw-w-96">
              <p class="tw-text-xs mb-8 tw-font-medium tw-text-enumDarkBlue">STEP {{ stepNumber }} OF 3 </p>
              <h2 class="tw-text-2xl tw-leading-7 mb-3 tw-text-enumDarkBlue tw-font-semibold">{{ stepTitle }}</h2>
              <p class="tw-text-xs md:tw-text-sm tw-leading-6 tw-text-enumGrey2">{{ stepDescription }}</p>

              <keep-alive>
                <component v-bind:is="returnMainContent()"></component>
              </keep-alive>

            </v-card>
          </div>

        </div>
      </div>

    </template>
  </layout>


</template>

<script>
import CreateCompanyStepOne from "@/components/onboarding/components/createCompany/CreateCompanyStepOne";
import CreateCompanyStepTwo from "@/components/onboarding/components/createCompany/CreateCompanyStepTwo";
import Onboardlayout from "@/components/onboarding/layouts/Onboardlayout";
import {ChevronLeftIcon} from "vue-feather-icons"
import CreateCompanyStepThree from "@/components/onboarding/components/createCompany/CreateCompanyStepThree";
import Header from "@/components/onboarding/reuseables/Header";
import BaseButton from "@/components/UIComponents/BaseButton";

export default {
  name: "CreateCompanyProfileView",
  data() {
    return {
      step: this.$route.params.step,
      backRoute: ""
    }
  },
  components: {
    BaseButton,
    Header,
    layout: Onboardlayout,
    ChevronLeftIcon,
    CreateCompanyStepThree,
    CreateCompanyStepTwo,
    CreateCompanyStepOne
  },
  computed: {
    first_login() {
      let firstLogin = JSON.parse(sessionStorage.getItem('userData')).first_login
      console.log(firstLogin)
      return firstLogin
    },
    stepNumber() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "1",
        "step 2": "2",
        "step 3": "3",
      }
      return titles['step ' + step]
    },
    stepTitle() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "Create an Organization",
        "step 2": "Make the most of your business",
        "step 3": "Tell us a bit about your organization",
      }
      return titles['step ' + step]
    },
    stepDescription() {
      const {$route: {params: {step}}} = this
      const titles = {
        "step 1": "Set up your business profile in few steps.",
        "step 2": "Make it easy to differentiate you from the pack.",
        "step 3": "This would help us personalise your experience and let applicants know what you do.\n",
      }
      return titles['step ' + step]
    }
  },
  methods: {
    returnMainContent() {
      let step = this.$route.params.step
      if (step === "1") {
        return "CreateCompanyStepOne";

      } else if (step === "2") {
        return "CreateCompanyStepTwo";
      } else if (step === "3") {
        return "CreateCompanyStepThree";
      }
    },
    goBack() {
      let newStep = "";
      let step = this.$route.params.step
      if (step === "2") {
        newStep = "1"
      } else if (step === "3") {
        newStep = "2"
      }
      this.$router.push({
        name: "CreateCompanyProfileView",
        params: {step: newStep}
      });
    },
    goBackOnPageOne() {
      console.log(this.backRoute)
      this.$router.push({
        name: this.backRoute
      });
    },
    goToAllOrganizationsPage() {
      this.$router.push({
        path: "/all-organizations"
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    let fromRoute = from.name
    console.log(from.name)
    // console.log(this.fromRoute)
    console.log(fromRoute)
    next(vm => {
      vm.$data.backRoute = fromRoute
    })

  }

}
</script>

<style scoped>
p {
  font-family: "DM Sans", serif;
}

.company__profile-content {
  height: calc(100% - 64px);
}

.main__content_wrap {
  min-height: calc(100vh);
}
</style>
