<template>
  <div>
    <div class="mt-6">
      <ValidationObserver v-slot="{handleSubmit}">
        <v-form id="createProfile" @submit.prevent="handleSubmit(moveToStepTwo)">
          <div class="my-4">
            <div  class=" d-flex">
              <small >Enter your Role in this Organization</small>
              <p class="err ml-1">*</p>
            </div>
            <ValidationProvider
                name="job role"
                :rules="{
                  required: true,
                  regex:  /^([A-Z,a-z\s()]+)$/
                 }"
                v-slot="{ errors }">
            <v-autocomplete id="jobRole"
                        hide-details solo
                        v-model="jobRole"
                        :items="sortedJobs"
                        :error="error"
                        label="Enter your role" />
              <span v-show="errors" class="err">{{handleJob(errors[0])}}</span>
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <div class=" d-flex">
              <small>Name of Organization</small>
              <p class="err ml-1">*</p>
            </div>
            <ValidationProvider
                name="company name"
                rules="required|min:3"
                v-slot="{ errors }">
            <v-text-field id="companyName" @input="(e) => handleChange(e)"
                      hide-details solo v-model="companyName"
                      label="Enter your company name" />
              <span v-show="errors" class="err">{{ errors[0] }} </span>
            </ValidationProvider>
          </div>

          <div class="mb-4">
            <div class=" d-flex">
              <small>Site Name</small>
              <p class="err ml-1">*</p>
            </div>
            <ValidationProvider
                name="site name"
                :rules="{
                  required: true,
                  regex:  /^([A-Z,a-z,0-9-_\s]+)$/,
                  min: 3
                 }"

                v-slot="{ errors }">
            <v-text-field :error="error"
                          :append-icon="!error ? 'mdi-check' : 'mdi-close'"
                          :loading="loading"
                          id="siteName"
                          hide-details
                          v-model="siteName"
                          :suffix="`.enum.africa`" />
              <span v-show="errors" class="err">{{handleError( errors[0], siteNameError)}} </span>
            </ValidationProvider>
          </div>

          <div class=" mt-12">
            <base-button :disabled="disable === true" type="submit" id="continue" :depressed="true" :width="'150px'" button-text="Continue">
              <template #right-icon>
                <ArrowRightIcon size="20" class="custom-class"/>
              </template>
            </base-button>
          </div>
        </v-form>
      </ValidationObserver>

    </div>

  </div>
</template>

<script>
import {ArrowRightIcon} from "vue-feather-icons"
import BaseButton from "@/components/UIComponents/BaseButton";
import jobRoles from "@/JsonFiles/jobRoles";
import {checkSiteNameExist, getAllSiteName} from "@/services/api/APIService"
export default {
name: "CreateCompanyStepOne",
  components:{BaseButton, ArrowRightIcon},
  data: () => ({
    jobRoles,
    jobRole: "",
    companyName: "",
    siteName:"",
    disable: false,
    company_details:"",
    inputCheck: "",
    loading: false,
    error: false,
    submit: false,
    siteNameError:"",
    allSiteName: []
  }),
  computed: {
  sortedJobs(){
    const jobRoles = this.jobRoles.jobRoles
    return jobRoles.sort()
  }

  },
  created() {
    let checkUpdatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
    // console.log(checkUpdatedDetails)
    if (checkUpdatedDetails !== null) {
      this.company_details = checkUpdatedDetails
      this.setSelected()
    }
  },
  methods: {
  handleError(error, siteNameError){
    if (error &&!siteNameError){
      return error
    }else if(siteNameError && !error){
      return siteNameError
    }else if (error && siteNameError){
      return siteNameError
    }
    setTimeout(() => {
      error = "";
      siteNameError = ""
    }, 3000);
  },
    handleJob(error) {
      if (error){
        this.disable = true
        return error
      }
      else {
        this.disable = false
      }
      setTimeout(() => {
        error = "";
      }, 3000);
    },
    handleChange(e){
      this.inputCheck = e.toLowerCase().split(/\s/).join('')
      this.siteName = this.inputCheck
    //   if (this.inputCheck === ""){
    //     this.siteName = this.companyName
    //   }else this.siteName = e
    //   // this.siteName = e
    },
    setSelected(){
      this.jobRole = this.company_details[0].job_role
      this.companyName = this.company_details[0].company_name
      this.siteName = this.company_details[0].site_name
      if (this.inputCheck === ""){
        this.siteName = this.companyName
      }
    },
    moveToStepTwo(){
      let companyDetails =[ {
        company_name: this.companyName.toLowerCase(),
        job_role: this.jobRole,
        site_name: this.siteName.toLowerCase().split(/\s/).join('')
      }]
      console.log(companyDetails)
      checkSiteNameExist(companyDetails[0].site_name).then(async res => {
        console.log(res)
        if (res.data.exist) {
          this.$displaySnackbar({message: 'Site name already exists', success: false})
          return
        } else {
          if(this.disable)return
          sessionStorage.setItem("companyDetails", JSON.stringify(companyDetails));
          await this.$router.push({name: 'CreateCompanyProfileView', params: {step: '2'}})
        }
      })
    },
    getSiteName(siteName, newValue){
      getAllSiteName(siteName).then(
          res =>{console.log(res)
          this.allSiteName = res.data
            if(!this.allSiteName.includes(newValue.toLowerCase()+ ".enum.africa")){
              this.loading = false
              this.submit= false
              this.error = false

            }else{
              this.siteNameError= "This site name already exists, please input another"
              setTimeout(() => {
                this.siteNameError = "";
              }, 3000);
              this.loading = false
              this.submit= true
              this.error = true
            }
          }
      ).catch( err =>{console.log(err)
        this.loading = false
        this.submit= true
      })
    }
  },

  watch:{
    siteName: function(newValue, oldValue) {
      if(newValue.length < 3){
        this.loading = false
        this.submit= false
      }
      if(newValue.length >= 3){
        this.loading = true
        this.submit= true
       let val = newValue.substring(0,3).toLowerCase()
        console.log(val)

        let inputtedValue = newValue.replace(/[^A-Z,a-z,0-9-_]+/ig, "_");
        this.getSiteName(val, inputtedValue)
      }
      console.log(newValue, oldValue)
    }
  }
}
</script>

<style scoped>
p{
  font-family: "DM Sans",serif;
}
.custom-class{
  color: white;
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
</style>
