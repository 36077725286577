<template>
  <div>
  <div>
   <div class="mt-7">
     <ValidationObserver v-slot="{handleSubmit}">
       <v-form id="createProfile" @submit.prevent="handleSubmit(proceed)">
         <div class="mb-4">
           <div class="mb-1">
             <small>Size</small>
           </div>
           <v-select id="companySize" hide-details solo v-model="size" :items="companySize"  >
           </v-select>
         </div>
        <div class="mb-4">
          <div class=" d-flex">
            <small >Short Description</small>
            <p class="err ml-1">*</p>
          </div>
          <ValidationProvider
              name="About Company"
              rules="required|max:500"
              v-slot="{ errors }">
          <v-textarea id="description" hide-details solo v-model="description" />
            <span v-show="errors" class="err">{{ errors[0] }} </span>
          </ValidationProvider>
          <div> <small >You can always change this later.</small></div>
        </div>
         <div class="width">
           <div class="d-flex">
             <small>Are you hiring</small>
             <p class="err ml-1">*</p>
           </div>
           <div class="lg:tw-w-full tw-flex tw-flex-wrap">
             <div
                 v-for="hire in hiring"
                 :id="hire"
                 class="tw-mr-3 tw-mt-1"
                 :class="[hire === option ? 'selected': 'select']"
                 :key="hire"
                 @click="handleSelectedHiring(hire)"
             >
               {{hire}}
             </div>
           </div>
           <span class="err">{{hiringError}}</span>
         </div>

         <div class="tw-flex mt-10">
            <base-button id="proceed" :loading="loading" type="submit" :depressed="true" class="mr-4" :width="'240px'" button-text="Proceed to Dashboard"></base-button>
          </div>
       </v-form>
     </ValidationObserver>
    </div>

  </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {createCompanyProfile} from "@/services/api/APIService";

export default {
name: "CreateCompanyStepThree",
  components:{BaseButton},
  data(){
  return{
    description: "",
    loading: false,
    hiringError: '',
    hiring : ['Yes', 'No'],
    option: '',
    select: null,
    size: "",
    companySize:[
      "1 - 5",
      "6 - 10",
      "11 - 50",
      "51 - 200",
      "201 - 500",
      "501 - 1000",
      "1001 - 5000",
      "5001 - 10000",
      "10000+"
    ],
  }
  },
  computed:{
    getUserTokenData(){
      return JSON.parse(sessionStorage.getItem('userData'))
    }
  },
  watch: {
    hiringError: {
      handler: function (newVal) {
        if(newVal) {
          setTimeout(() => this.hiringError = '', 3000);
        }
      }
    }
  },
  methods:{
    handleSelectedHiring(hire) {
      if(hire === 'Yes') {
        this.select = true

      }
      else if(hire === 'No') {
        this.select = false
      }
      return this.option = hire
    },
    checkSalary() {
      if(this.select) {
        return true
      }
      else if(this.select === null) {
        this.hiringError = 'Please select one option'
        return false
      }
    },
    proceed(){
      if(this.select === null){
        this.hiringError = 'Please select one option'
        return
      }
      this.loading = true
      let checkUpdatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
      console.log(checkUpdatedDetails)
      if (checkUpdatedDetails === null){
        this.$router.push({name: 'RecruiterDashboard'})
      }
      else {
        let newData = JSON.parse(sessionStorage.getItem("companyDetails"));
        let stepThreeDetails = {
          company_size:this.size,
          company_overview: this.description,
          hiring: this.select
        }
        newData.push(stepThreeDetails)
        sessionStorage.setItem("companyDetails", JSON.stringify(newData));

        let updatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
        let updatedDetailsObject = Object.assign(updatedDetails[0], updatedDetails[1], updatedDetails[2])
        updatedDetailsObject.site_name = updatedDetailsObject.site_name + ".enum.africa"
        console.log(updatedDetailsObject)
        createCompanyProfile(updatedDetailsObject).then(
            res => {
              console.log(res)
              let userToken = {...this.getUserTokenData}
              userToken.site_name = updatedDetailsObject.site_name.toLowerCase()
              sessionStorage.setItem("userData", JSON.stringify(userToken));
              let userData = JSON.parse(sessionStorage.getItem("userData"));
              if (userData.first_login){
                this.$router.push({name: 'RecruiterDashboard'})
              } else this.$router.push({name: 'ViewAllOrganizationPage'})

              sessionStorage.removeItem("companyDetails");
              let updatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
              console.log(updatedDetails, "after delete")
            }
        ).catch(
            err => {
              console.log(err)

            }
        ).finally(() => {
          this.loading = false
        })
      }
    },

  }
}
</script>

<style scoped>
p{
  font-family: "DM Sans",serif;
}
.err{
  font-family: "DM Sans",serif;
  color: red;
  font-size: 13px;
}
.select{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}

.select-web{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}
.select-web:hover{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;

}


.selected{
  padding: 16px 32px;
  width: max-content;
  height: 56px;
  background: #F7FCFF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #008EEF;
  cursor: pointer;
}
</style>