var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-6"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({handleSubmit}){return [_c('v-form',{attrs:{"id":"createProfile"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.moveToStepTwo)}}},[_c('div',{staticClass:"my-4"},[_c('div',{staticClass:"d-flex"},[_c('small',[_vm._v("Enter your Role in this Organization")]),_c('p',{staticClass:"err ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"job role","rules":{
                required: true,
                regex:  /^([A-Z,a-z\s()]+)$/
               }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"id":"jobRole","hide-details":"","solo":"","items":_vm.sortedJobs,"error":_vm.error,"label":"Enter your role"},model:{value:(_vm.jobRole),callback:function ($$v) {_vm.jobRole=$$v},expression:"jobRole"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"err"},[_vm._v(_vm._s(_vm.handleJob(errors[0])))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex"},[_c('small',[_vm._v("Name of Organization")]),_c('p',{staticClass:"err ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"company name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"id":"companyName","hide-details":"","solo":"","label":"Enter your company name"},on:{"input":(e) => _vm.handleChange(e)},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"err"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"d-flex"},[_c('small',[_vm._v("Site Name")]),_c('p',{staticClass:"err ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"site name","rules":{
                required: true,
                regex:  /^([A-Z,a-z,0-9-_\s]+)$/,
                min: 3
               }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error":_vm.error,"append-icon":!_vm.error ? 'mdi-check' : 'mdi-close',"loading":_vm.loading,"id":"siteName","hide-details":"","suffix":`.enum.africa`},model:{value:(_vm.siteName),callback:function ($$v) {_vm.siteName=$$v},expression:"siteName"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(errors),expression:"errors"}],staticClass:"err"},[_vm._v(_vm._s(_vm.handleError( errors[0], _vm.siteNameError))+" ")])]}}],null,true)})],1),_c('div',{staticClass:"mt-12"},[_c('base-button',{attrs:{"disabled":_vm.disable === true,"type":"submit","id":"continue","depressed":true,"width":'150px',"button-text":"Continue"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('ArrowRightIcon',{staticClass:"custom-class",attrs:{"size":"20"}})]},proxy:true}],null,true)})],1)])]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }