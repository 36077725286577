<template>
  <div>
    <div>
      <div class="mt-6">
        <ValidationObserver v-slot="{handleSubmit}">
          <v-form id="createProfile" @submit.prevent="handleSubmit(moveToStepThree)">
            <div class="mb-4">
              <div class="mb-1">
                <small>Company Industry</small>
              </div>
              <v-combobox id="industry" hide-details solo v-model="industry" :items="industries.Industries"
                          label="Select the Industry">
              </v-combobox>
            </div>
            <div class="mb-4">
              <div class="mb-1">
                <small>Primary Location</small>
              </div>
              <v-combobox id="location" hide-details solo v-model="location" :items="countries"
                          label="Select the location">
              </v-combobox>
            </div>

            <div class="mb-4">
              <div class="mb-1">
                <small>Website (optional)</small>
              </div>
              <ValidationProvider
                  name="company name"
                  :rules="{
                regex: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
                }"
                  v-slot="{ errors }">
                <v-text-field id="website" type="text" hide-details solo
                              v-model="website" label="https://"
                />
                <span v-show="errors" class="err">{{
                    errors[0]
                  }}{{
                    errors[0] === "The company name field format is invalid" ? ", should begin with https" : ""
                  }} </span>
              </ValidationProvider>
            </div>

            <div class="my-4">
              <div class="mb-1 tw-flex">
                <small>Company Logo (optional)</small>
              </div>
              <v-card :loading="loading" flat v-model="showDragDiv">
                <div
                    v-show="file === ''"
                    :style="{ 'background-color': color }"
                    class="drag-div tw-bg-enumBlue5"
                    @dragleave="dragleave"
                    @dragover="dragover"
                    @drop="drop"
                    id="drop"

                >
                  <input
                      id="assetsFieldHandle"
                      ref="file"
                      accept="image/*"
                      class="w-px h-px opacity-0 overflow-hidden absolute"
                      name="fields[assetsFieldHandle][]"
                      style="display: none"
                      type="file"
                      @change="uploadToCloudinary()"


                  />
                  <label class="d-flex align-center justify-center upload-label flex-column cursor-pointer"
                         for="assetsFieldHandle">
                    <UploadIcon class="tw-text-enumGrey2"/>
                    <span class="drag-drop mx-auto">
                    <p class="font-weight-bold tw-text-enumGrey2 my-2">Drag and drop or <span class="choose__file">choose image</span></p>
                    <p class="size__instruction tw-text-enumGrey3 d-block ">240x346 px Recommended, max size 24MB </p>
                  </span>
                  </label>
                </div>
              </v-card>
              <div v-if="!company_logo && loading" class="file" style="cursor: pointer">
                <div class="d-flex align-center justify-center pt-3 px-3">
                  <span>Loading...</span>
                </div>
              </div>
              <div v-if="company_logo" class="" style="cursor: pointer">
                <div class="tw-flex align-center">
                  <div class="tw-flex align-center">
                    <v-img
                        :src="company_logo"
                        class="round-image pr-2"
                        transition="fade"
                        height="118"
                        width="150"
                        contain

                    />
                    <div class="tw-flex tw-flex-col">
                      <span class="tw-pl-3" v-if="file.name">{{ shortenedTitle(file.name) }}</span>
                      <!--                      <p class="tw-pl-3 fontSize" v-if="file.size">Size: {{ getSize(file.size) }}</p>-->
                      <span class="image__actions d-flex align-end " :class="{ 'bg-transparent': company_logo }">
                        <span class="tw-flex lg:tw-flex-row tw-flex-col  pl-3" v-if="company_logo">
                          <v-btn
                              class="mr-3 tw-mb-5 lg:tw-mb-0"
                              depressed
                              @click.prevent="openExplorer"

                          >
                            Replace
                          </v-btn>
                        </span>
                      </span>

                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="tw-flex mt-12">
              <base-button @click="$router.push({name: 'CreateCompanyProfileView', params:{step: '3'}})"
                           :depressed="true" id="skip" class="mr-4" :outlined="true" button-text="Skip"></base-button>
              <base-button type="submit" :disabled="loading" :depressed="true" id="continue" :width="'150px'"
                           button-text="Continue">
                <template #right-icon>
                  <ArrowRightIcon class="custom-class"/>
                </template>
              </base-button>
            </div>
          </v-form>
        </ValidationObserver>
      </div>

    </div>
  </div>
</template>

<script>
import {ArrowRightIcon, UploadIcon} from "vue-feather-icons"
import BaseButton from "@/components/UIComponents/BaseButton";
import {getCriteriaCountries} from "@/services/api/APIService";
import industries from "@/JsonFiles/industries";
import {uploadMediaFile} from "@/services/utils/fileUploader";

export default {
  name: "CreateCompanyStepTwo",
  components: {BaseButton, ArrowRightIcon, UploadIcon},
  data() {
    return {
      showDragDiv: true,
      website: "",
      logo: "",
      file: "",
      color: "",
      countries: ["Lagos, Nigeria"],
      allCountries:[],
      loading: false,
      location: "",
      industry: "",
      company_logo: "",
      company_logo_url: '',
      industries,
    }
  },
  async created() {
    let checkUpdatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
    console.log(checkUpdatedDetails)
    if (checkUpdatedDetails !== null && checkUpdatedDetails[1] !== undefined) {
      let check = "website" in checkUpdatedDetails[1]
      if (check === true) {
        this.industry = checkUpdatedDetails[1].industry
        this.company_logo = checkUpdatedDetails[1].company_logo
        this.website = checkUpdatedDetails[1].website
      }
    }
    await this.getAllCountries()

  },
  methods: {
    getAllCountries() {
      getCriteriaCountries().then(res => {
        this.countries.push(...res.data.map(({capital, name}) => {
          if(!capital){
            return name.common
          }
          return `${capital[0]}, ${name.common}`
        }))
      })
          .catch(err => {
            console.log({err})
          })
    },
    async handleImage(file) {
      const { key: company_logo_url, name: company_logo, size: company_logo_file_size } = await uploadMediaFile(file, 'company/logo')
      let companyLogo = {company_logo_url, company_logo, company_logo_file_size};
      this.company_logo_url = companyLogo.company_logo_url
      console.log(company_logo_url)
      const fr = new FileReader()
      fr.onload = ({ target: { result } }) => {
        this.company_logo = result
      }
      fr.readAsDataURL(file)
    },
    async uploadToCloudinary() {
      this.loading = true
      this.file = this.$refs.file.files[0];
      console.log(this.file)
      const exts = ['png', 'jpeg', 'jpg', 'tiff']
      let splitFile = this.file.name.split(".")
      let fileExtension = splitFile[splitFile.length - 1]
      if (!exts.includes(fileExtension.toLowerCase())) {
        this.$displaySnackbar({message: 'Only accepts Image files', success: false})
        this.loading=false
        this.file = "";
        return
      }
      console.log(this.file)

      try {

        await this.handleImage(this.file);
        this.loading = false
      } catch (e) {
        this.loading = false;

      }
    },
    async moveToStepThree() {
      let checkUpdatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
      console.log(checkUpdatedDetails)
      if (checkUpdatedDetails === null) {
        await this.$router.push({name: 'RecruiterDashboard'})
      } else {
        let newData = JSON.parse(sessionStorage.getItem("companyDetails"));
        let stepTwoDetails = {
          industry: this.industry,
          company_logo: this.company_logo_url,
          website: this.website,
          location: this.location,
        }
        console.log(stepTwoDetails)
        newData.push(stepTwoDetails)
        sessionStorage.setItem("companyDetails", JSON.stringify(newData));
        let updatedDetails = JSON.parse(sessionStorage.getItem("companyDetails"));
        console.log(updatedDetails)
        await this.$router.push({name: 'CreateCompanyProfileView', params: {step: '3'}})
      }
    },

    shortenedTitle(file_name) {
      const shortenText = (text, length) => {
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
      return shortenText(file_name, 20)
    },
    onChange() {
      this.file = this.$refs.file.files[0];
      this.showDragDiv = false;
    },
    dragover(event) {
      event.preventDefault();
      this.color = "#F6F6F6";
    },
    dragleave() {
      // Clean up
      this.color = "#FBFDFE";
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      this.uploadToCloudinary()
      this.color = "#FBFDFE";
    },
    remove() {
      this.file = "";
      this.showDragDiv = true;
    },
    openExplorer() {
      this.$refs.file.click();
    },
  }
}
</script>

<style scoped>
p {
  font-family: "DM Sans", serif;
}

.custom-class {
  color: white;
}

.drag-div {
  /*background: #FBFDFE;*/
  border: 0.5px solid #eaeaea;
  box-sizing: border-box;
  border-radius: 10px;
  /*width: 420px;*/
  height: 118px;
  /*margin-top: 24px;*/
}

.drag-drop {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.file {
  border-radius: 8px;
  width: 280px;
  height: 50px;
  border: 1px solid #eaeaea;
  /*margin-top: px;*/
  margin-bottom: 28px;
}

.upload-label {
  height: 100%;
  cursor: pointer;
}

.choose__file {
  color: #008EEF;
  font-family: "DM Sans", serif;
}

.size__instruction {
  font-size: 11px;
}
</style>
