export default {
    jobRoles: [
        "Product Manager",
        "Product designer (UX)",
        "Frontend Software Engineer",
        "Backend Software Engineer",
        "Data Analyst",
        "Content Marketing Associate",
        "SEO Consultant",
        "System Administrator",
        "Visual Designer",
        "Software Training Facilitator",
        "Graphic Designer",
        "Mobile Developer",
        "Full Stack Developer",
        "IT Help Desk",
        "Technician",
        "Social Media Officer",
        "Web Developer",
        "Project Management Analyst",
        "Web Analytics Developer",
        "Digital Marketing Manager",
        "Network Support Engineer",
        "Regional Developer",
        "Content Manager",
        "Content Strategist",
        "Frontend React Native",
        "Project Engineer",
        "Integration Engineer",
        "Quality Assurance Engineer",
        "Solution and Research Engineer",
        "Solution Architect",
        "Enterprise Architect",
        "Chief Information Officer",
        "Chief Technical Officer",
        "Technology Cloud Sales Representative",
        "Java Microservices Engineer",
        "Interaction Designer",
        "DevOps Engineer",
        "Technical Writer",
        "Customer Engagement Manager",
        "Software Engineer",
        "WordPress Developer Framework Specialist",
        "Ruby on Rails Developer",
        "Python Developer",
        "Business Systems Analyst",
        "Block Chain Developer",
        "Maintenance Engineer and Machine Operator",
        "Data Architect",
        "Data Modeler",
        "Data Scientist",
        "Cloud Architect",
        "Technical Lead",
        "DevOps Manager",
        "Agile Project Manager",
        "Technical Account Manager",
        "Security Specialist",
        "Quality Assurance Specialist",
        "Game Developer",
        "Computer Graphics Animator",
        "Machine Learning Engineer"
    ]
}